var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"systemLog-list-container"},[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',[_c('b-card-title',[_vm._v(_vm._s(_vm.t('Lịch sử thao tác')))])],1),_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('select-manager',{attrs:{"include-owner":true},model:{value:(_vm.manager),callback:function ($$v) {_vm.manager=$$v},expression:"manager"}})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-input',{attrs:{"placeholder":`${_vm.t('Tìm kiếm')}...`,"debounce":"1000"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1)],1),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"cols":"12"}},[_c('vue-good-table',{attrs:{"style-class":"vgt-table striped bordered","mode":"remote","total-rows":_vm.totalRecords,"is-loading":_vm.isLoading,"columns":_vm.columns,"rows":_vm.rows,"search-options":{
              enabled: false,
            },"pagination-options":{
              enabled: true,
              perPage: _vm.serverParams.perPage,
            }},on:{"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event},"on-selected-rows-change":_vm.selectionChanged,"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'code')?_c('span',[_c('b-link',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-systemLog-detail",modifiers:{"modal-systemLog-detail":true}}],staticClass:"font-weight-bold",on:{"click":function($event){return _vm.onEditItem(props.row)}}},[_vm._v(" "+_vm._s(props.row.code)+" ")])],1):(props.column.field === 'status')?_c('span',[_c('b-badge',{staticClass:"font-small-1",attrs:{"variant":props.row.statusObject.variant}},[_c('span',[_vm._v(_vm._s(props.row.statusObject.title))])])],1):(props.column.field === 'createdAt')?_c('span',[_vm._v(" "+_vm._s(_vm.parseDateToString(props.row.createdAt, 'DD-MM-YYYY HH:mm'))+" ")]):(props.column.field === 'action')?_c('span',[_c('table-actions',{attrs:{"name":"","modal":"modal-systemLog","hide-delete":!_vm.$can('delete', 'systemLog'),"hide-edit":!_vm.$can('update', 'systemLog')},on:{"on-edit":function($event){return _vm.onEditItem(props.row)},"on-delete":function($event){return _vm.onDelete(props.row)}}})],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.t('Hiển thị tối đa'))+" ")]),_c('b-form-select',{staticClass:"mx-1",attrs:{"options":['10', '20', '50', '100', '500']},on:{"input":(value) =>
                        props.perPageChanged({ currentPerPage: value })},model:{value:(_vm.serverParams.perPage),callback:function ($$v) {_vm.$set(_vm.serverParams, "perPage", $$v)},expression:"serverParams.perPage"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.t('trên tổng số'))+" "+_vm._s(props.total)+" "+_vm._s(_vm.t('kết quả'))+" ")])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"total-rows":props.total,"per-page":_vm.serverParams.perPage,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":(value) => props.pageChanged({ currentPage: value })},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}])},[_c('template',{slot:"loadingContent"},[_c('b-spinner',{attrs:{"label":"Loading","type":"grow"}})],1),_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_c('div',{staticClass:"text-center text-muted"},[_vm._v(" "+_vm._s(_vm.t('Không có bản ghi nào!'))+" ")])])],2)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }